import React from 'react'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import Box from '../components/fragments/Box'

const ReviewTemaplate = ({ data, pageContext }) => {
  return (
    <Layout title='Reviews' subtitle='read em all, might also get em all?'>
      {data.allGoogleDocs.nodes.map((e, i) => (
        <div key={i} className='column is-full'>
          <Box
            title={e.name}
            authorID={e.authorID}
            description={e.description}
            image={e.cover}
            path={e.path}
            date={e.createdTime}
          />
        </div>
      ))}

      <nav
        className='pagination mt-6'
        role='navigation'
        aria-label='pagination'
      >
        <Link
          to={
            pageContext.currentPage == 2
              ? '/reviews'
              : `/reviews/${pageContext.currentPage - 1}`
          }
          className='pagination-previous'
          title='This is the first page'
          disabled={pageContext.currentPage == 1 ? true : false}
        >
          Previous
        </Link>
        <Link
          to={`/reviews/${pageContext.currentPage + 1}`}
          className='pagination-next'
          disabled={
            pageContext.currentPage == pageContext.numPages ? true : false
          }
        >
          Next page
        </Link>
        <ul className='pagination-list'>
          {Array.from({ length: pageContext.numPages }).map((e, i) => (
            <li key={i}>
              <Link
                to={i == 0 ? '/reviews' : `/reviews/${i + 1}`}
                className={
                  i == pageContext.currentPage - 1
                    ? 'pagination-link is-current'
                    : 'pagination-link'
                }
                aria-label={`Page${i}`}
                aria-current='page'
              >
                {i + 1}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </Layout>
  )
}
export const fetchLatestBlogPaginatedQuery = graphql`
  query allPostsMDXReviews($skip: Int, $postsPerPage: Int) {
    allGoogleDocs(skip: $skip, limit: $postsPerPage) {
      nodes {
        name
        authorID
        description
        createdTime(fromNow: true)
        path
        cover {
          image {
            childImageSharp {
              fluid(maxWidth: 128) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
export default ReviewTemaplate
